<template>
	<div class="systemInfo gradient-background">
		<h1>Diagnostic Data</h1>
		<div class="diagnostics">
			<p><strong>Environment Variables:</strong> {{ environmentVariables }}</p>
			<p><strong>adminState:</strong> {{ adminData }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "SystemInfo",
	props: {
		adminState: Object,
		isMobile: Boolean,
	},
	data() {
		return {
			adminData: JSON.stringify(this.adminState, null, "\t"),
			environmentVariables: process.env,
		};
	},
	watch: {
		adminState: {
			handler() {
				this.updateSysInfo();
			},
			deep: true,
		},
	},
	methods: {
		updateSysInfo() {
			this.adminData = JSON.stringify(this.adminState, null, "\t");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
	overflow: hidden;
}
h1 {
	text-align: center;
}
.diagnostics {
	/* position: absolute; */
	display: flex;
	flex-flow: column;
	/* top: 0; */
	width: 80%;
	margin: 15px auto 45px;
	padding: 30px;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 8px;
}
.diagnostics p,
.diagnostics pre {
	font-family: "Courier New", monospace;
	overflow-wrap: anywhere;
	white-space: pre-wrap;
}
</style>
